<template>
  <div>
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
  </div>
</template>

<script setup>
import {
  ApolloClient,
  ApolloLink,
  concat,
  createHttpLink,
  InMemoryCache,
} from "@apollo/client/core";
import { createApp, provide, h, computed } from "vue";
import {
  DefaultApolloClient,
  provideApolloClient,
} from "@vue/apollo-composable";
import { GET_META } from '~/graphql/GetMeta.js';

// HTTP connection to the API
const httpLink = createHttpLink({
  // ToDo: Set GraphQL Endpoint with publicRuntimeConfig environment variable
  // see: https://nuxtjs.org/tutorials/moving-from-nuxtjs-dotenv-to-runtime-config/
  // uri: 'http://localhost:8080/graphql',
  // uri: "https://staging-livebouldercreek.kinsta.cloud/wp/graphql",
  uri: "https://flintrockbuilders.kinsta.cloud/graphql",
});

// Cache implementation
const cache = new InMemoryCache({
  typePolicies: {
    Page_Hlorganisms_hlOrganism: {
      // ToDo: Implement automatic unique ID ACF field for organisms
      // Since there is no obvious unique id field, and the id field
      // returns null, the Apollo cache will repeat the first organism
      // over and over because it can't tell the difference.
      // Here we define keyFields for the cache, hoping that every
      // organism has a unique combination of the following.
      // This should work for now, but maybe either revisit this
      // policy, or define a truly unique id field to use instead.
      //
      // Somebody made a plugin for this, but it hasn't been updated in
      // 8 years. One possible approach though:
      // https://github.com/KLicheR/wp-acf-unique_id
      keyFields: [
        "type",
        "title1",
        "title2",
        "title3",
        "text1",
        "id",
        "cssClasses",
        "description",
        "item",
      ],
    },
    Page_Hlorganisms_hlOrganism_item: {
      keyFields: [
        "type",
        "title1",
        "title2",
        "title3",
        "text1",
        "id",
        "cssClasses",
        "description",
        "image",
        "imageLink",
      ],
    },
    Campaign_Hlorganisms_hlOrganism_item: {
      keyFields: [
        "type",
        "title1",
        "title2",
        "title3",
        "text1",
        "id",
        "cssClasses",
        "description",
        "image",
        "imageLink",
      ],
    },
    User: {
      keyFields: ["id", "databaseId", "favorites"],
    },
    Neighborhood_Neighborhood: {
      merge: true,
    },
    Model_Modeldetails: {
      merge: true,
    },
    Plan_Floorplandetails: {
      merge: true,
    },
    Event: {
      keyFields: ["id", "databaseId", "uri"],
    },
  },
});

// Middleware for adding the Authorization token to headers
const authMiddleware = new ApolloLink((operation, forward) => {
  const cookie = useCookie("apollo:favorites.token");
  const token = cookie.value;

  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      Authorization: token ? `Bearer ${token}` : "",
    },
  }));

  return forward(operation);
});

// Create the apollo client
const apolloClient = new ApolloClient({
  link: concat(authMiddleware, httpLink),
  cache,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "cache-first",
    },
    query: {
      fetchPolicy: "cache-first",
    },
  },
});

const app = createApp({
  setup() {
    provide(DefaultApolloClient, apolloClient);
  },
  render: () => h(App),
});

provideApolloClient(apolloClient);

const route = useRoute();
const { result, loading } = useQuery(GET_META, { uri: route.path });

// Create a reactive object for meta data
const meta = reactive({
  title: "Flintrock Builders",
  description: "",
  // other meta data...
});

onBeforeMount(async () => {
  watch([result, loading], () => {
    if (!loading.value) {
      const data = result.value ?? [];

      // Update the meta data
      meta.name = data?.page?.seoMeta?.name ?? meta?.name;
      meta.description = data?.page?.seoMeta?.description ?? meta?.description;
      let ogImage = 'https://flintrockbuilders.com/img/Flintrock_Icon.svg';

      useHead({
        title: "Flintrock Builders",
        meta: [
          {
            property: 'og:image',
            content: ogImage
          },
          {
            property: 'og:type',
            content: 'website'
          },
          {
            property: 'og:title',
            content: meta?.name || 'Explore Quality Homes from Flintrock Builders'
          },
          {
            property: 'og:description',
            content: meta?.description || 'Discover quality craftsmanship and exceptional design with Flintrock Builders, creating beautiful, custom homes in Central Texas.'
          },
          {
            name: 'google-site-verification',
            content: 'HRtYmBQaXUwWidH1LG4BxZ1MqoL3lzk1S_5NWokpqOs'
          },
          // Add more tags as needed
        ],
        htmlAttrs: {
          class: "scroll-smooth",
        },
        link: [
          {
            rel: "icon",
            type: "image/x-icon",
            href: "/img/Flintrock_Icon.svg",
          },
        ],
        script: [
          {
            type: "text/javascript",
            src: "//js.hs-scripts.com/43635150.js",
            async: true,
            defer: true,
            id: "hs-script-loader",
            tagPosition: "bodyClose",
          },
          {
            type: "text/javascript",
            innerHTML: `
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-PCG2TB7');`,
          },
        ],
        script: [
          { src: '//cdn.rlets.com/capture_configs/73c/dc4/82b/e2444c2943febe1564e733e.js', async: true }
        ],
        noscript: [
          {
            children:
              '<noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-PCG2TB7" height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>',
            body: "bodyOpen",
          },
        ],
      });
    }
  });
});

// ToDo: Dynamically add page title & description. Maybe pull from Yoast

</script>
