import { default as energy_45green_45practicesHlno4qR9mWMeta } from "/workspaces/app/nuxtapp/pages/about-us/energy-green-practices.vue?macro=true";
import { default as financing1pUmPgy7ReMeta } from "/workspaces/app/nuxtapp/pages/about-us/financing.vue?macro=true";
import { default as homesafe_45colorado_45master_45builderHSquRvs0ftMeta } from "/workspaces/app/nuxtapp/pages/about-us/homesafe-colorado-master-builder.vue?macro=true";
import { default as indexm9uvOdVD6AMeta } from "/workspaces/app/nuxtapp/pages/about-us/index.vue?macro=true";
import { default as our_45storyJWh7YYzk7bMeta } from "/workspaces/app/nuxtapp/pages/about-us/our-story.vue?macro=true";
import { default as our_45teamVL9lmjKM4SMeta } from "/workspaces/app/nuxtapp/pages/about-us/our-team.vue?macro=true";
import { default as preferred_45lenderBMXc4u7y57Meta } from "/workspaces/app/nuxtapp/pages/about-us/preferred-lender.vue?macro=true";
import { default as realtor_45resourcestfOM1nAWHcMeta } from "/workspaces/app/nuxtapp/pages/about-us/realtor-resources.vue?macro=true";
import { default as copperas_45cove_45areaDhhJqjEH5jMeta } from "/workspaces/app/nuxtapp/pages/areas/copperas-cove-area.vue?macro=true";
import { default as georgetown_45areaYUEYXLfdtZMeta } from "/workspaces/app/nuxtapp/pages/areas/georgetown-area.vue?macro=true";
import { default as killeen_45areawTsrYzeBfjMeta } from "/workspaces/app/nuxtapp/pages/areas/killeen-area.vue?macro=true";
import { default as lorena_45areaL45umzMdjiMeta } from "/workspaces/app/nuxtapp/pages/areas/lorena-area.vue?macro=true";
import { default as nolanville_45areaCDAuD4Sg2kMeta } from "/workspaces/app/nuxtapp/pages/areas/nolanville-area.vue?macro=true";
import { default as salado_45areaWgDN1y7Hq6Meta } from "/workspaces/app/nuxtapp/pages/areas/salado-area.vue?macro=true";
import { default as temple_45areaOScCOX9SWRMeta } from "/workspaces/app/nuxtapp/pages/areas/temple-area.vue?macro=true";
import { default as troy_45areav66kcmv74UMeta } from "/workspaces/app/nuxtapp/pages/areas/troy-area.vue?macro=true";
import { default as _91post_93VQvyWJ5PPZMeta } from "/workspaces/app/nuxtapp/pages/blog/[post].vue?macro=true";
import { default as indexxifeFVV1LgMeta } from "/workspaces/app/nuxtapp/pages/blog/category/[category]/index.vue?macro=true";
import { default as indexSRoYJhim1QMeta } from "/workspaces/app/nuxtapp/pages/blog/index.vue?macro=true";
import { default as _91_46_46_46slug_93fEnyxKK7lXMeta } from "/workspaces/app/nuxtapp/pages/campaigns/[...slug].vue?macro=true";
import { default as indextaeUE2gDrOMeta } from "/workspaces/app/nuxtapp/pages/campaigns/index.vue?macro=true";
import { default as indexI6eiCaQXSuMeta } from "/workspaces/app/nuxtapp/pages/communities/[community]/index.vue?macro=true";
import { default as ask_45a_45questionAZK3udQM6gMeta } from "/workspaces/app/nuxtapp/pages/contact-us/ask-a-question.vue?macro=true";
import { default as career_45opportunities27Xs427gTXMeta } from "/workspaces/app/nuxtapp/pages/contact-us/career-opportunities.vue?macro=true";
import { default as faqN9jv2Szv04Meta } from "/workspaces/app/nuxtapp/pages/contact-us/faq.vue?macro=true";
import { default as homeowner_45resourcesQL7quSEWX8Meta } from "/workspaces/app/nuxtapp/pages/contact-us/homeowner-resources.vue?macro=true";
import { default as indexBZ2cHG9W2TMeta } from "/workspaces/app/nuxtapp/pages/contact-us/index.vue?macro=true";
import { default as our_45locationsfOUzPkQtlmMeta } from "/workspaces/app/nuxtapp/pages/contact-us/our-locations.vue?macro=true";
import { default as trade_45partner_45inquiryRPUj2mxnzuMeta } from "/workspaces/app/nuxtapp/pages/contact-us/trade-partner-inquiry.vue?macro=true";
import { default as current_45offersKzEPYooo5CMeta } from "/workspaces/app/nuxtapp/pages/current-offers.vue?macro=true";
import { default as indexlRYwbesVxsMeta } from "/workspaces/app/nuxtapp/pages/events/[event]/index.vue?macro=true";
import { default as indexJUMys9OEoBMeta } from "/workspaces/app/nuxtapp/pages/events/index.vue?macro=true";
import { default as indexFnyUd0k75cMeta } from "/workspaces/app/nuxtapp/pages/homes/[location]/[community]/index.vue?macro=true";
import { default as indexc14fD6gvPPMeta } from "/workspaces/app/nuxtapp/pages/homes/plans/[plan]/index.vue?macro=true";
import { default as indexst9dnEQJOjMeta } from "/workspaces/app/nuxtapp/pages/index.vue?macro=true";
import { default as land_45acquisitionLafEBemF8JMeta } from "/workspaces/app/nuxtapp/pages/land-acquisition.vue?macro=true";
import { default as brents_45placeJPK8ZXJwZwMeta } from "/workspaces/app/nuxtapp/pages/lifestyle/brents-place.vue?macro=true";
import { default as experience_45lower_45maintenanceEp9ffTTvTdMeta } from "/workspaces/app/nuxtapp/pages/lifestyle/experience-lower-maintenance.vue?macro=true";
import { default as _91testimonial_93GjQKAKBsc4Meta } from "/workspaces/app/nuxtapp/pages/lifestyle/reviews/[testimonial].vue?macro=true";
import { default as index5KZVzyo7UmMeta } from "/workspaces/app/nuxtapp/pages/lifestyle/reviews/category/[category]/index.vue?macro=true";
import { default as indexqlvpV5XF56Meta } from "/workspaces/app/nuxtapp/pages/lifestyle/reviews/index.vue?macro=true";
import { default as whats_45lifefullnessoXOYAJXUDtMeta } from "/workspaces/app/nuxtapp/pages/lifestyle/whats-lifefullness.vue?macro=true";
import { default as _91_46_46_46slug_935pJm0iYxxGMeta } from "/workspaces/app/nuxtapp/pages/marshallfire/[...slug].vue?macro=true";
import { default as indexRxP8QLJH4uMeta } from "/workspaces/app/nuxtapp/pages/models/[model]/index.vue?macro=true";
import { default as my_45favorites3UgQC5S31GMeta } from "/workspaces/app/nuxtapp/pages/my-favorites.vue?macro=true";
import { default as new_45home_45search5mMlkGOvfMMeta } from "/workspaces/app/nuxtapp/pages/new-home-search.vue?macro=true";
import { default as communitiesl5lic0Vk5KMeta } from "/workspaces/app/nuxtapp/pages/our-homes/communities.vue?macro=true";
import { default as easyhousetIzEop76oCMeta } from "/workspaces/app/nuxtapp/pages/our-homes/home-collections/easyhouse.vue?macro=true";
import { default as limitededition5Ci0oTlXUdMeta } from "/workspaces/app/nuxtapp/pages/our-homes/home-collections/limitededition.vue?macro=true";
import { default as wee_45cottageHokiwBWvyiMeta } from "/workspaces/app/nuxtapp/pages/our-homes/home-collections/wee-cottage.vue?macro=true";
import { default as indexmGMIUo9pHIMeta } from "/workspaces/app/nuxtapp/pages/our-homes/index.vue?macro=true";
import { default as privacytermsnELsPPY2W0Meta } from "/workspaces/app/nuxtapp/pages/privacyterms.vue?macro=true";
import { default as index4oLGK9hUQyMeta } from "/workspaces/app/nuxtapp/pages/qmi/[qmi]/index.vue?macro=true";
import { default as sandbox2U8XnS9wpcMeta } from "/workspaces/app/nuxtapp/pages/sandbox.vue?macro=true";
import { default as the_45preserveBtQpYWMFXoMeta } from "/workspaces/app/nuxtapp/pages/the-preserve.vue?macro=true";
import { default as the_45ridge_45at_45knob_45creekwliu6wTACiMeta } from "/workspaces/app/nuxtapp/pages/the-ridge-at-knob-creek.vue?macro=true";
export default [
  {
    name: energy_45green_45practicesHlno4qR9mWMeta?.name ?? "about-us-energy-green-practices",
    path: energy_45green_45practicesHlno4qR9mWMeta?.path ?? "/about-us/energy-green-practices",
    meta: energy_45green_45practicesHlno4qR9mWMeta || {},
    alias: energy_45green_45practicesHlno4qR9mWMeta?.alias || [],
    redirect: energy_45green_45practicesHlno4qR9mWMeta?.redirect || undefined,
    component: () => import("/workspaces/app/nuxtapp/pages/about-us/energy-green-practices.vue").then(m => m.default || m)
  },
  {
    name: financing1pUmPgy7ReMeta?.name ?? "about-us-financing",
    path: financing1pUmPgy7ReMeta?.path ?? "/about-us/financing",
    meta: financing1pUmPgy7ReMeta || {},
    alias: financing1pUmPgy7ReMeta?.alias || [],
    redirect: financing1pUmPgy7ReMeta?.redirect || undefined,
    component: () => import("/workspaces/app/nuxtapp/pages/about-us/financing.vue").then(m => m.default || m)
  },
  {
    name: homesafe_45colorado_45master_45builderHSquRvs0ftMeta?.name ?? "about-us-homesafe-colorado-master-builder",
    path: homesafe_45colorado_45master_45builderHSquRvs0ftMeta?.path ?? "/about-us/homesafe-colorado-master-builder",
    meta: homesafe_45colorado_45master_45builderHSquRvs0ftMeta || {},
    alias: homesafe_45colorado_45master_45builderHSquRvs0ftMeta?.alias || [],
    redirect: homesafe_45colorado_45master_45builderHSquRvs0ftMeta?.redirect || undefined,
    component: () => import("/workspaces/app/nuxtapp/pages/about-us/homesafe-colorado-master-builder.vue").then(m => m.default || m)
  },
  {
    name: indexm9uvOdVD6AMeta?.name ?? "about-us",
    path: indexm9uvOdVD6AMeta?.path ?? "/about-us",
    meta: indexm9uvOdVD6AMeta || {},
    alias: indexm9uvOdVD6AMeta?.alias || [],
    redirect: indexm9uvOdVD6AMeta?.redirect || undefined,
    component: () => import("/workspaces/app/nuxtapp/pages/about-us/index.vue").then(m => m.default || m)
  },
  {
    name: our_45storyJWh7YYzk7bMeta?.name ?? "about-us-our-story",
    path: our_45storyJWh7YYzk7bMeta?.path ?? "/about-us/our-story",
    meta: our_45storyJWh7YYzk7bMeta || {},
    alias: our_45storyJWh7YYzk7bMeta?.alias || [],
    redirect: our_45storyJWh7YYzk7bMeta?.redirect || undefined,
    component: () => import("/workspaces/app/nuxtapp/pages/about-us/our-story.vue").then(m => m.default || m)
  },
  {
    name: our_45teamVL9lmjKM4SMeta?.name ?? "about-us-our-team",
    path: our_45teamVL9lmjKM4SMeta?.path ?? "/about-us/our-team",
    meta: our_45teamVL9lmjKM4SMeta || {},
    alias: our_45teamVL9lmjKM4SMeta?.alias || [],
    redirect: our_45teamVL9lmjKM4SMeta?.redirect || undefined,
    component: () => import("/workspaces/app/nuxtapp/pages/about-us/our-team.vue").then(m => m.default || m)
  },
  {
    name: preferred_45lenderBMXc4u7y57Meta?.name ?? "about-us-preferred-lender",
    path: preferred_45lenderBMXc4u7y57Meta?.path ?? "/about-us/preferred-lender",
    meta: preferred_45lenderBMXc4u7y57Meta || {},
    alias: preferred_45lenderBMXc4u7y57Meta?.alias || [],
    redirect: preferred_45lenderBMXc4u7y57Meta?.redirect || undefined,
    component: () => import("/workspaces/app/nuxtapp/pages/about-us/preferred-lender.vue").then(m => m.default || m)
  },
  {
    name: realtor_45resourcestfOM1nAWHcMeta?.name ?? "about-us-realtor-resources",
    path: realtor_45resourcestfOM1nAWHcMeta?.path ?? "/about-us/realtor-resources",
    meta: realtor_45resourcestfOM1nAWHcMeta || {},
    alias: realtor_45resourcestfOM1nAWHcMeta?.alias || [],
    redirect: realtor_45resourcestfOM1nAWHcMeta?.redirect || undefined,
    component: () => import("/workspaces/app/nuxtapp/pages/about-us/realtor-resources.vue").then(m => m.default || m)
  },
  {
    name: copperas_45cove_45areaDhhJqjEH5jMeta?.name ?? "areas-copperas-cove-area",
    path: copperas_45cove_45areaDhhJqjEH5jMeta?.path ?? "/areas/copperas-cove-area",
    meta: copperas_45cove_45areaDhhJqjEH5jMeta || {},
    alias: copperas_45cove_45areaDhhJqjEH5jMeta?.alias || [],
    redirect: copperas_45cove_45areaDhhJqjEH5jMeta?.redirect || undefined,
    component: () => import("/workspaces/app/nuxtapp/pages/areas/copperas-cove-area.vue").then(m => m.default || m)
  },
  {
    name: georgetown_45areaYUEYXLfdtZMeta?.name ?? "areas-georgetown-area",
    path: georgetown_45areaYUEYXLfdtZMeta?.path ?? "/areas/georgetown-area",
    meta: georgetown_45areaYUEYXLfdtZMeta || {},
    alias: georgetown_45areaYUEYXLfdtZMeta?.alias || [],
    redirect: georgetown_45areaYUEYXLfdtZMeta?.redirect || undefined,
    component: () => import("/workspaces/app/nuxtapp/pages/areas/georgetown-area.vue").then(m => m.default || m)
  },
  {
    name: killeen_45areawTsrYzeBfjMeta?.name ?? "areas-killeen-area",
    path: killeen_45areawTsrYzeBfjMeta?.path ?? "/areas/killeen-area",
    meta: killeen_45areawTsrYzeBfjMeta || {},
    alias: killeen_45areawTsrYzeBfjMeta?.alias || [],
    redirect: killeen_45areawTsrYzeBfjMeta?.redirect || undefined,
    component: () => import("/workspaces/app/nuxtapp/pages/areas/killeen-area.vue").then(m => m.default || m)
  },
  {
    name: lorena_45areaL45umzMdjiMeta?.name ?? "areas-lorena-area",
    path: lorena_45areaL45umzMdjiMeta?.path ?? "/areas/lorena-area",
    meta: lorena_45areaL45umzMdjiMeta || {},
    alias: lorena_45areaL45umzMdjiMeta?.alias || [],
    redirect: lorena_45areaL45umzMdjiMeta?.redirect || undefined,
    component: () => import("/workspaces/app/nuxtapp/pages/areas/lorena-area.vue").then(m => m.default || m)
  },
  {
    name: nolanville_45areaCDAuD4Sg2kMeta?.name ?? "areas-nolanville-area",
    path: nolanville_45areaCDAuD4Sg2kMeta?.path ?? "/areas/nolanville-area",
    meta: nolanville_45areaCDAuD4Sg2kMeta || {},
    alias: nolanville_45areaCDAuD4Sg2kMeta?.alias || [],
    redirect: nolanville_45areaCDAuD4Sg2kMeta?.redirect || undefined,
    component: () => import("/workspaces/app/nuxtapp/pages/areas/nolanville-area.vue").then(m => m.default || m)
  },
  {
    name: salado_45areaWgDN1y7Hq6Meta?.name ?? "areas-salado-area",
    path: salado_45areaWgDN1y7Hq6Meta?.path ?? "/areas/salado-area",
    meta: salado_45areaWgDN1y7Hq6Meta || {},
    alias: salado_45areaWgDN1y7Hq6Meta?.alias || [],
    redirect: salado_45areaWgDN1y7Hq6Meta?.redirect || undefined,
    component: () => import("/workspaces/app/nuxtapp/pages/areas/salado-area.vue").then(m => m.default || m)
  },
  {
    name: temple_45areaOScCOX9SWRMeta?.name ?? "areas-temple-area",
    path: temple_45areaOScCOX9SWRMeta?.path ?? "/areas/temple-area",
    meta: temple_45areaOScCOX9SWRMeta || {},
    alias: temple_45areaOScCOX9SWRMeta?.alias || [],
    redirect: temple_45areaOScCOX9SWRMeta?.redirect || undefined,
    component: () => import("/workspaces/app/nuxtapp/pages/areas/temple-area.vue").then(m => m.default || m)
  },
  {
    name: troy_45areav66kcmv74UMeta?.name ?? "areas-troy-area",
    path: troy_45areav66kcmv74UMeta?.path ?? "/areas/troy-area",
    meta: troy_45areav66kcmv74UMeta || {},
    alias: troy_45areav66kcmv74UMeta?.alias || [],
    redirect: troy_45areav66kcmv74UMeta?.redirect || undefined,
    component: () => import("/workspaces/app/nuxtapp/pages/areas/troy-area.vue").then(m => m.default || m)
  },
  {
    name: _91post_93VQvyWJ5PPZMeta?.name ?? "blog-post",
    path: _91post_93VQvyWJ5PPZMeta?.path ?? "/blog/:post()",
    meta: _91post_93VQvyWJ5PPZMeta || {},
    alias: _91post_93VQvyWJ5PPZMeta?.alias || [],
    redirect: _91post_93VQvyWJ5PPZMeta?.redirect || undefined,
    component: () => import("/workspaces/app/nuxtapp/pages/blog/[post].vue").then(m => m.default || m)
  },
  {
    name: indexxifeFVV1LgMeta?.name ?? "blog-category-category",
    path: indexxifeFVV1LgMeta?.path ?? "/blog/category/:category()",
    meta: indexxifeFVV1LgMeta || {},
    alias: indexxifeFVV1LgMeta?.alias || [],
    redirect: indexxifeFVV1LgMeta?.redirect || undefined,
    component: () => import("/workspaces/app/nuxtapp/pages/blog/category/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: indexSRoYJhim1QMeta?.name ?? "blog",
    path: indexSRoYJhim1QMeta?.path ?? "/blog",
    meta: indexSRoYJhim1QMeta || {},
    alias: indexSRoYJhim1QMeta?.alias || [],
    redirect: indexSRoYJhim1QMeta?.redirect || undefined,
    component: () => import("/workspaces/app/nuxtapp/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93fEnyxKK7lXMeta?.name ?? "campaigns-slug",
    path: _91_46_46_46slug_93fEnyxKK7lXMeta?.path ?? "/campaigns/:slug(.*)*",
    meta: _91_46_46_46slug_93fEnyxKK7lXMeta || {},
    alias: _91_46_46_46slug_93fEnyxKK7lXMeta?.alias || [],
    redirect: _91_46_46_46slug_93fEnyxKK7lXMeta?.redirect || undefined,
    component: () => import("/workspaces/app/nuxtapp/pages/campaigns/[...slug].vue").then(m => m.default || m)
  },
  {
    name: indextaeUE2gDrOMeta?.name ?? "campaigns",
    path: indextaeUE2gDrOMeta?.path ?? "/campaigns",
    meta: indextaeUE2gDrOMeta || {},
    alias: indextaeUE2gDrOMeta?.alias || [],
    redirect: indextaeUE2gDrOMeta?.redirect || undefined,
    component: () => import("/workspaces/app/nuxtapp/pages/campaigns/index.vue").then(m => m.default || m)
  },
  {
    name: indexI6eiCaQXSuMeta?.name ?? "communities-community",
    path: indexI6eiCaQXSuMeta?.path ?? "/communities/:community()",
    meta: indexI6eiCaQXSuMeta || {},
    alias: indexI6eiCaQXSuMeta?.alias || [],
    redirect: indexI6eiCaQXSuMeta?.redirect || undefined,
    component: () => import("/workspaces/app/nuxtapp/pages/communities/[community]/index.vue").then(m => m.default || m)
  },
  {
    name: ask_45a_45questionAZK3udQM6gMeta?.name ?? "contact-us-ask-a-question",
    path: ask_45a_45questionAZK3udQM6gMeta?.path ?? "/contact-us/ask-a-question",
    meta: ask_45a_45questionAZK3udQM6gMeta || {},
    alias: ask_45a_45questionAZK3udQM6gMeta?.alias || [],
    redirect: ask_45a_45questionAZK3udQM6gMeta?.redirect || undefined,
    component: () => import("/workspaces/app/nuxtapp/pages/contact-us/ask-a-question.vue").then(m => m.default || m)
  },
  {
    name: career_45opportunities27Xs427gTXMeta?.name ?? "contact-us-career-opportunities",
    path: career_45opportunities27Xs427gTXMeta?.path ?? "/contact-us/career-opportunities",
    meta: career_45opportunities27Xs427gTXMeta || {},
    alias: career_45opportunities27Xs427gTXMeta?.alias || [],
    redirect: career_45opportunities27Xs427gTXMeta?.redirect || undefined,
    component: () => import("/workspaces/app/nuxtapp/pages/contact-us/career-opportunities.vue").then(m => m.default || m)
  },
  {
    name: faqN9jv2Szv04Meta?.name ?? "contact-us-faq",
    path: faqN9jv2Szv04Meta?.path ?? "/contact-us/faq",
    meta: faqN9jv2Szv04Meta || {},
    alias: faqN9jv2Szv04Meta?.alias || [],
    redirect: faqN9jv2Szv04Meta?.redirect || undefined,
    component: () => import("/workspaces/app/nuxtapp/pages/contact-us/faq.vue").then(m => m.default || m)
  },
  {
    name: homeowner_45resourcesQL7quSEWX8Meta?.name ?? "contact-us-homeowner-resources",
    path: homeowner_45resourcesQL7quSEWX8Meta?.path ?? "/contact-us/homeowner-resources",
    meta: homeowner_45resourcesQL7quSEWX8Meta || {},
    alias: homeowner_45resourcesQL7quSEWX8Meta?.alias || [],
    redirect: homeowner_45resourcesQL7quSEWX8Meta?.redirect || undefined,
    component: () => import("/workspaces/app/nuxtapp/pages/contact-us/homeowner-resources.vue").then(m => m.default || m)
  },
  {
    name: indexBZ2cHG9W2TMeta?.name ?? "contact-us",
    path: indexBZ2cHG9W2TMeta?.path ?? "/contact-us",
    meta: indexBZ2cHG9W2TMeta || {},
    alias: indexBZ2cHG9W2TMeta?.alias || [],
    redirect: indexBZ2cHG9W2TMeta?.redirect || undefined,
    component: () => import("/workspaces/app/nuxtapp/pages/contact-us/index.vue").then(m => m.default || m)
  },
  {
    name: our_45locationsfOUzPkQtlmMeta?.name ?? "contact-us-our-locations",
    path: our_45locationsfOUzPkQtlmMeta?.path ?? "/contact-us/our-locations",
    meta: our_45locationsfOUzPkQtlmMeta || {},
    alias: our_45locationsfOUzPkQtlmMeta?.alias || [],
    redirect: our_45locationsfOUzPkQtlmMeta?.redirect || undefined,
    component: () => import("/workspaces/app/nuxtapp/pages/contact-us/our-locations.vue").then(m => m.default || m)
  },
  {
    name: trade_45partner_45inquiryRPUj2mxnzuMeta?.name ?? "contact-us-trade-partner-inquiry",
    path: trade_45partner_45inquiryRPUj2mxnzuMeta?.path ?? "/contact-us/trade-partner-inquiry",
    meta: trade_45partner_45inquiryRPUj2mxnzuMeta || {},
    alias: trade_45partner_45inquiryRPUj2mxnzuMeta?.alias || [],
    redirect: trade_45partner_45inquiryRPUj2mxnzuMeta?.redirect || undefined,
    component: () => import("/workspaces/app/nuxtapp/pages/contact-us/trade-partner-inquiry.vue").then(m => m.default || m)
  },
  {
    name: current_45offersKzEPYooo5CMeta?.name ?? "current-offers",
    path: current_45offersKzEPYooo5CMeta?.path ?? "/current-offers",
    meta: current_45offersKzEPYooo5CMeta || {},
    alias: current_45offersKzEPYooo5CMeta?.alias || [],
    redirect: current_45offersKzEPYooo5CMeta?.redirect || undefined,
    component: () => import("/workspaces/app/nuxtapp/pages/current-offers.vue").then(m => m.default || m)
  },
  {
    name: indexlRYwbesVxsMeta?.name ?? "events-event",
    path: indexlRYwbesVxsMeta?.path ?? "/events/:event()",
    meta: indexlRYwbesVxsMeta || {},
    alias: indexlRYwbesVxsMeta?.alias || [],
    redirect: indexlRYwbesVxsMeta?.redirect || undefined,
    component: () => import("/workspaces/app/nuxtapp/pages/events/[event]/index.vue").then(m => m.default || m)
  },
  {
    name: indexJUMys9OEoBMeta?.name ?? "events",
    path: indexJUMys9OEoBMeta?.path ?? "/events",
    meta: indexJUMys9OEoBMeta || {},
    alias: indexJUMys9OEoBMeta?.alias || [],
    redirect: indexJUMys9OEoBMeta?.redirect || undefined,
    component: () => import("/workspaces/app/nuxtapp/pages/events/index.vue").then(m => m.default || m)
  },
  {
    name: indexFnyUd0k75cMeta?.name ?? "homes-location-community",
    path: indexFnyUd0k75cMeta?.path ?? "/homes/:location()/:community()",
    meta: indexFnyUd0k75cMeta || {},
    alias: indexFnyUd0k75cMeta?.alias || [],
    redirect: indexFnyUd0k75cMeta?.redirect || undefined,
    component: () => import("/workspaces/app/nuxtapp/pages/homes/[location]/[community]/index.vue").then(m => m.default || m)
  },
  {
    name: indexc14fD6gvPPMeta?.name ?? "homes-plans-plan",
    path: indexc14fD6gvPPMeta?.path ?? "/homes/plans/:plan()",
    meta: indexc14fD6gvPPMeta || {},
    alias: indexc14fD6gvPPMeta?.alias || [],
    redirect: indexc14fD6gvPPMeta?.redirect || undefined,
    component: () => import("/workspaces/app/nuxtapp/pages/homes/plans/[plan]/index.vue").then(m => m.default || m)
  },
  {
    name: indexst9dnEQJOjMeta?.name ?? "index",
    path: indexst9dnEQJOjMeta?.path ?? "/",
    meta: indexst9dnEQJOjMeta || {},
    alias: indexst9dnEQJOjMeta?.alias || [],
    redirect: indexst9dnEQJOjMeta?.redirect || undefined,
    component: () => import("/workspaces/app/nuxtapp/pages/index.vue").then(m => m.default || m)
  },
  {
    name: land_45acquisitionLafEBemF8JMeta?.name ?? "land-acquisition",
    path: land_45acquisitionLafEBemF8JMeta?.path ?? "/land-acquisition",
    meta: land_45acquisitionLafEBemF8JMeta || {},
    alias: land_45acquisitionLafEBemF8JMeta?.alias || [],
    redirect: land_45acquisitionLafEBemF8JMeta?.redirect || undefined,
    component: () => import("/workspaces/app/nuxtapp/pages/land-acquisition.vue").then(m => m.default || m)
  },
  {
    name: brents_45placeJPK8ZXJwZwMeta?.name ?? "lifestyle-brents-place",
    path: brents_45placeJPK8ZXJwZwMeta?.path ?? "/lifestyle/brents-place",
    meta: brents_45placeJPK8ZXJwZwMeta || {},
    alias: brents_45placeJPK8ZXJwZwMeta?.alias || [],
    redirect: brents_45placeJPK8ZXJwZwMeta?.redirect || undefined,
    component: () => import("/workspaces/app/nuxtapp/pages/lifestyle/brents-place.vue").then(m => m.default || m)
  },
  {
    name: experience_45lower_45maintenanceEp9ffTTvTdMeta?.name ?? "lifestyle-experience-lower-maintenance",
    path: experience_45lower_45maintenanceEp9ffTTvTdMeta?.path ?? "/lifestyle/experience-lower-maintenance",
    meta: experience_45lower_45maintenanceEp9ffTTvTdMeta || {},
    alias: experience_45lower_45maintenanceEp9ffTTvTdMeta?.alias || [],
    redirect: experience_45lower_45maintenanceEp9ffTTvTdMeta?.redirect || undefined,
    component: () => import("/workspaces/app/nuxtapp/pages/lifestyle/experience-lower-maintenance.vue").then(m => m.default || m)
  },
  {
    name: _91testimonial_93GjQKAKBsc4Meta?.name ?? "lifestyle-reviews-testimonial",
    path: _91testimonial_93GjQKAKBsc4Meta?.path ?? "/lifestyle/reviews/:testimonial()",
    meta: _91testimonial_93GjQKAKBsc4Meta || {},
    alias: _91testimonial_93GjQKAKBsc4Meta?.alias || [],
    redirect: _91testimonial_93GjQKAKBsc4Meta?.redirect || undefined,
    component: () => import("/workspaces/app/nuxtapp/pages/lifestyle/reviews/[testimonial].vue").then(m => m.default || m)
  },
  {
    name: index5KZVzyo7UmMeta?.name ?? "lifestyle-reviews-category-category",
    path: index5KZVzyo7UmMeta?.path ?? "/lifestyle/reviews/category/:category()",
    meta: index5KZVzyo7UmMeta || {},
    alias: index5KZVzyo7UmMeta?.alias || [],
    redirect: index5KZVzyo7UmMeta?.redirect || undefined,
    component: () => import("/workspaces/app/nuxtapp/pages/lifestyle/reviews/category/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: indexqlvpV5XF56Meta?.name ?? "lifestyle-reviews",
    path: indexqlvpV5XF56Meta?.path ?? "/lifestyle/reviews",
    meta: indexqlvpV5XF56Meta || {},
    alias: indexqlvpV5XF56Meta?.alias || [],
    redirect: indexqlvpV5XF56Meta?.redirect || undefined,
    component: () => import("/workspaces/app/nuxtapp/pages/lifestyle/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: whats_45lifefullnessoXOYAJXUDtMeta?.name ?? "lifestyle-whats-lifefullness",
    path: whats_45lifefullnessoXOYAJXUDtMeta?.path ?? "/lifestyle/whats-lifefullness",
    meta: whats_45lifefullnessoXOYAJXUDtMeta || {},
    alias: whats_45lifefullnessoXOYAJXUDtMeta?.alias || [],
    redirect: whats_45lifefullnessoXOYAJXUDtMeta?.redirect || undefined,
    component: () => import("/workspaces/app/nuxtapp/pages/lifestyle/whats-lifefullness.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_935pJm0iYxxGMeta?.name ?? "marshallfire-slug",
    path: _91_46_46_46slug_935pJm0iYxxGMeta?.path ?? "/marshallfire/:slug(.*)*",
    meta: _91_46_46_46slug_935pJm0iYxxGMeta || {},
    alias: _91_46_46_46slug_935pJm0iYxxGMeta?.alias || [],
    redirect: _91_46_46_46slug_935pJm0iYxxGMeta?.redirect || undefined,
    component: () => import("/workspaces/app/nuxtapp/pages/marshallfire/[...slug].vue").then(m => m.default || m)
  },
  {
    name: indexRxP8QLJH4uMeta?.name ?? "models-model",
    path: indexRxP8QLJH4uMeta?.path ?? "/models/:model()",
    meta: indexRxP8QLJH4uMeta || {},
    alias: indexRxP8QLJH4uMeta?.alias || [],
    redirect: indexRxP8QLJH4uMeta?.redirect || undefined,
    component: () => import("/workspaces/app/nuxtapp/pages/models/[model]/index.vue").then(m => m.default || m)
  },
  {
    name: my_45favorites3UgQC5S31GMeta?.name ?? "my-favorites",
    path: my_45favorites3UgQC5S31GMeta?.path ?? "/my-favorites",
    meta: my_45favorites3UgQC5S31GMeta || {},
    alias: my_45favorites3UgQC5S31GMeta?.alias || [],
    redirect: my_45favorites3UgQC5S31GMeta?.redirect || undefined,
    component: () => import("/workspaces/app/nuxtapp/pages/my-favorites.vue").then(m => m.default || m)
  },
  {
    name: new_45home_45search5mMlkGOvfMMeta?.name ?? "new-home-search",
    path: new_45home_45search5mMlkGOvfMMeta?.path ?? "/new-home-search",
    meta: new_45home_45search5mMlkGOvfMMeta || {},
    alias: new_45home_45search5mMlkGOvfMMeta?.alias || [],
    redirect: new_45home_45search5mMlkGOvfMMeta?.redirect || undefined,
    component: () => import("/workspaces/app/nuxtapp/pages/new-home-search.vue").then(m => m.default || m)
  },
  {
    name: communitiesl5lic0Vk5KMeta?.name ?? "our-homes-communities",
    path: communitiesl5lic0Vk5KMeta?.path ?? "/our-homes/communities",
    meta: communitiesl5lic0Vk5KMeta || {},
    alias: communitiesl5lic0Vk5KMeta?.alias || [],
    redirect: communitiesl5lic0Vk5KMeta?.redirect || undefined,
    component: () => import("/workspaces/app/nuxtapp/pages/our-homes/communities.vue").then(m => m.default || m)
  },
  {
    name: easyhousetIzEop76oCMeta?.name ?? "our-homes-home-collections-easyhouse",
    path: easyhousetIzEop76oCMeta?.path ?? "/our-homes/home-collections/easyhouse",
    meta: easyhousetIzEop76oCMeta || {},
    alias: easyhousetIzEop76oCMeta?.alias || [],
    redirect: easyhousetIzEop76oCMeta?.redirect || undefined,
    component: () => import("/workspaces/app/nuxtapp/pages/our-homes/home-collections/easyhouse.vue").then(m => m.default || m)
  },
  {
    name: limitededition5Ci0oTlXUdMeta?.name ?? "our-homes-home-collections-limitededition",
    path: limitededition5Ci0oTlXUdMeta?.path ?? "/our-homes/home-collections/limitededition",
    meta: limitededition5Ci0oTlXUdMeta || {},
    alias: limitededition5Ci0oTlXUdMeta?.alias || [],
    redirect: limitededition5Ci0oTlXUdMeta?.redirect || undefined,
    component: () => import("/workspaces/app/nuxtapp/pages/our-homes/home-collections/limitededition.vue").then(m => m.default || m)
  },
  {
    name: wee_45cottageHokiwBWvyiMeta?.name ?? "our-homes-home-collections-wee-cottage",
    path: wee_45cottageHokiwBWvyiMeta?.path ?? "/our-homes/home-collections/wee-cottage",
    meta: wee_45cottageHokiwBWvyiMeta || {},
    alias: wee_45cottageHokiwBWvyiMeta?.alias || [],
    redirect: wee_45cottageHokiwBWvyiMeta?.redirect || undefined,
    component: () => import("/workspaces/app/nuxtapp/pages/our-homes/home-collections/wee-cottage.vue").then(m => m.default || m)
  },
  {
    name: indexmGMIUo9pHIMeta?.name ?? "our-homes",
    path: indexmGMIUo9pHIMeta?.path ?? "/our-homes",
    meta: indexmGMIUo9pHIMeta || {},
    alias: indexmGMIUo9pHIMeta?.alias || [],
    redirect: indexmGMIUo9pHIMeta?.redirect || undefined,
    component: () => import("/workspaces/app/nuxtapp/pages/our-homes/index.vue").then(m => m.default || m)
  },
  {
    name: privacytermsnELsPPY2W0Meta?.name ?? "privacyterms",
    path: privacytermsnELsPPY2W0Meta?.path ?? "/privacyterms",
    meta: privacytermsnELsPPY2W0Meta || {},
    alias: privacytermsnELsPPY2W0Meta?.alias || [],
    redirect: privacytermsnELsPPY2W0Meta?.redirect || undefined,
    component: () => import("/workspaces/app/nuxtapp/pages/privacyterms.vue").then(m => m.default || m)
  },
  {
    name: index4oLGK9hUQyMeta?.name ?? "qmi-qmi",
    path: index4oLGK9hUQyMeta?.path ?? "/qmi/:qmi()",
    meta: index4oLGK9hUQyMeta || {},
    alias: index4oLGK9hUQyMeta?.alias || [],
    redirect: index4oLGK9hUQyMeta?.redirect || undefined,
    component: () => import("/workspaces/app/nuxtapp/pages/qmi/[qmi]/index.vue").then(m => m.default || m)
  },
  {
    name: sandbox2U8XnS9wpcMeta?.name ?? "sandbox",
    path: sandbox2U8XnS9wpcMeta?.path ?? "/sandbox",
    meta: sandbox2U8XnS9wpcMeta || {},
    alias: sandbox2U8XnS9wpcMeta?.alias || [],
    redirect: sandbox2U8XnS9wpcMeta?.redirect || undefined,
    component: () => import("/workspaces/app/nuxtapp/pages/sandbox.vue").then(m => m.default || m)
  },
  {
    name: the_45preserveBtQpYWMFXoMeta?.name ?? "the-preserve",
    path: the_45preserveBtQpYWMFXoMeta?.path ?? "/the-preserve",
    meta: the_45preserveBtQpYWMFXoMeta || {},
    alias: the_45preserveBtQpYWMFXoMeta?.alias || [],
    redirect: the_45preserveBtQpYWMFXoMeta?.redirect || undefined,
    component: () => import("/workspaces/app/nuxtapp/pages/the-preserve.vue").then(m => m.default || m)
  },
  {
    name: the_45ridge_45at_45knob_45creekwliu6wTACiMeta?.name ?? "the-ridge-at-knob-creek",
    path: the_45ridge_45at_45knob_45creekwliu6wTACiMeta?.path ?? "/the-ridge-at-knob-creek",
    meta: the_45ridge_45at_45knob_45creekwliu6wTACiMeta || {},
    alias: the_45ridge_45at_45knob_45creekwliu6wTACiMeta?.alias || [],
    redirect: the_45ridge_45at_45knob_45creekwliu6wTACiMeta?.redirect || undefined,
    component: () => import("/workspaces/app/nuxtapp/pages/the-ridge-at-knob-creek.vue").then(m => m.default || m)
  }
]